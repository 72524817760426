import printPreview from "@/view/reportDesign/preview2";
let hiprintTemplate, printData;

// crud交由presenter持有
const defaultForm = {
  id: null,
  hospitalId: null,
  hospital: null,
  userId: null,
  scaleId: null,
  scale: null,
  project: null,
  pregnancyId: null,
  score: null,
  result: null,
  state: null,
  startTime: null,
  endTime: null,
  recordId: null,
  remarks: null,
  isDel: null,
  createTime: null,
  updateTime: null
};
export default {
  name: "reportview",
  components: {
    printPreview
  },
  dicts: ["孕产机构", "孕产用户", "量表", "孕期阶段", "测评状态"],
  data() {
    return {
      reportZoom: "FitPage",
      fw: "川北医学院南充市身心医院(南充市高坪区小龙街道英才路166号)。<br>川北医学院心理健康实践教学中心(四川省南充市顺庆区培江路234号)。",
      // 报告信息
      res: {
        dimensionResult: [{
          createTime: "2023-12-28 11:35:54",
          updateTime: null,
          id: 41,
          plan: 0,
          scale: 2,
          student: 6,
          description: "总粗分=各条目得分之和  \n标准分=总粗分×1.25，取整数部分",
          remark: null,
          states: 1,
          recordId: "25",
          dimensionId: 33,
          title: "总分",
          dimensionScore: 53.0
        }],
        record: {
          createTime: "2023-12-28 11:35:23",
          updateTime: "2023-12-28 11:35:54",
          id: 25,
          hospitalId: 1,
          hospital: "南充市身心医院",
          recordId: null,
          userId: 6,
          scaleId: 2,
          scale: "抑郁-自评量表（SDS）",
          project: "手动下发",
          pregnancyId: 1,
          score: 53,
          result: null,
          state: 1,
          startTime: null,
          endTime: "2023-12-28 11:35:53",
          remarks: "admin"
        },
        scale: null,
        hospital: {
          createTime: "2023-11-21 11:43:56",
          updateTime: "2023-12-28 11:05:42",
          id: 1,
          hospital: "南充市身心医院",
          phone: "13419458588",
          address: "四川省南充市",
          remarks: null,
          titleImage: "/api/file/pic/20231228110536657793.jpg",
          signImage: "/api/file/pic/20231228110541494979.png",
          state: 0,
          types: 1,
          longitude: "32531.24",
          latitude: "35531.22"
        },
        dimension: [{
          createTime: "2023-11-24 09:09:48",
          updateTime: null,
          id: 33,
          scaleId: 2,
          title: "总分",
          types: 0,
          formula: "{1}+{2}+{3}+{4}+{5}+{6}+{7}+{8}+{9}+{10}+{11}+{12}+{13}+{14}+{15}+{16}+{17}+{18}+{19}+{20}",
          description: "总粗分=各条目得分之和  \n标准分=总粗分×1.25，取整数部分",
          states: 0,
          remark: "SDS评分标准：\n按照中国常模，\n53≤标准分＜63，轻度抑郁\n63≤标准分＜73，中度抑郁\n标准分≥73，重度抑郁"
        }],
        user: {
          createTime: "2023-12-04 08:13:30",
          updateTime: "2023-12-28 11:34:56",
          id: 6,
          uid: "o3pg16iIBPNkulFlezpRbBDOYnFg",
          names: "吴亮",
          idCard: "511322198601213370",
          hospitalId: 1,
          types: 1,
          menstruation: "2023-09-26",
          phone: "18188438610",
          phoneFamily: null,
          planed: null,
          weight: 30.0,
          marriageState: null,
          career: null,
          remarks: "",
          pregnancyId: 1,
          finishDate: "2023-12-03",
          terminationDate: "2023-12-04",
          pregnancyState: 0,
          state: 0,
          deliverDate: "2024-07-02",
          avatar: "https://thirdwx.qlogo.cn/mmopen/vi_32/8sBXWLCn0PWYicz4510nLntwLINndS77pKBJ0VDoynibicXO5fbIBnbcLUpCgWIG8Ks1ZDTu7Fm1GbN4TqDoJl9KQ/132",
          nickname: "\uD83D\uDC11\uD83D\uDC02\uD83D\uDC12\uD83D\uDC2F",
          hospital: "南充市身心医院"
        }
      },
      dialogXX: false,
      dialogFormEdit: false,
      permission: {
        add: ["admin", "scaleRecord:add"],
        edit: ["admin", "scaleRecord:edit"],
        del: ["admin", "scaleRecord:del"]
      },
      rules: {},
      tableData: []
      //打印设益打印设置
    };
  },

  watch: {},
  mounted() {
    console.log("------------mounted---------------");
    var query = this.$route.query;
    if (query.pid) {
      this.preViewFollowUp(query.pid, query.id);
    } else {
      this.preViewReport(query.id);
    }
  },
  methods: {
    preViewReport(id) {
      var that = this;
      console.log("------------preView---------------");
      that.$http.get('/api/ycf/getTestReport/' + id, null, '正在检索量表，请稍后').then(res => {
        console.log(res, "-----------报告详情");
        if (res.success) {
          this.res = res.data;
          hiprintTemplate = new hiprint.PrintTemplate({
            template: JSON.parse(this.res.template.data),
            // 模板json
            dataMode: 1,
            // 1:getJson 其他：getJsonTid 默认1
            onUpdateError: e => {
              // 更新失败回调
              console.log(e);
            }
          });
          hiprintTemplate.design("#aaa");
          this.$refs.preView.show(hiprintTemplate, this.res);
          this.showDialog = true;
          // loading.close();
        } else {
          this.$message.error(res.msg);
          loading.close();
        }
      });
    },
    onDocumentLoaded(a) {
      console.log("document loaded", a);
    },
    onReportLoaded(arg) {
      console.log("report loaded", arg);
    },
    getViewer() {
      return this.$refs.control;
    },
    // 获取报告
    getReport(id) {
      var that = this;
      const loading = this.$loading({
        lock: true,
        text: "后台拉取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      that.$http.get('/api/ycf/getTestReport/' + this.$route.query.id, null, '正在检索量表，请稍后').then(res => {
        console.log(res, "-----------报告详情");
        this.res = res.data;
        this.dialogFormEdit = true;
        loading.close();
      });
    },
    //点击取消需要关闭
    cancel() {
      this.dialogXX = false;
      this.dialogFormEdit = false;
    },
    //提交按钮
    edit() {
      //ajax发送成功的是响应的时候也需要要关闭弹出层
      this.dialogXX = false;
      this.dialogFormEdit = false;
    }
  }
};