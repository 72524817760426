import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-da619bf2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container",
  style: {
    "transform": "scale(0.4)",
    "transform-origin": "top left"
  }
};
const _hoisted_2 = {
  id: "designer-host"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_print_preview = _resolveComponent("print-preview");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_print_preview, {
    ref: "preView"
  }, null, 512)])]);
}