export default {
  name: "printPreview",
  props: {},
  data() {
    return {
      visible: false,
      spinning: true,
      waitShowPrinter: false,
      // 纸张宽 mm
      width: 0,
      // 模板
      hiprintTemplate: {},
      // 数据
      printData: {},
      vshow: false
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    hideModal() {
      this.visible = false;
    },
    show(hiprintTemplate, printData, width = "210") {
      this.vshow = true;
      console.log(printData);
      this.visible = true;
      this.spinning = true;
      this.width = hiprintTemplate.editingPanel ? hiprintTemplate.editingPanel.width : width;
      this.hiprintTemplate = hiprintTemplate;
      this.printData = printData;
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        $("#preview_content_design").html(hiprintTemplate.getHtml(printData));
        this.spinning = false;
      }, 500);
    },
    print() {
      this.waitShowPrinter = true;
      this.hiprintTemplate.print(this.printData, {}, {
        callback: () => {
          console.log("callback");
          this.waitShowPrinter = false;
        }
      });
    },
    sign() {
      console.log("callback");
      console.log(this.printData);
      crudScaleRecord.sign(this.printData.record.id).then(() => {
        this.$message.success("签名完成！");
      });
    },
    unsign() {
      console.log("callback");
      console.log(this.printData);
      crudScaleRecord.unsign(this.printData.record.id).then(() => {
        this.$message.success("取消成功！");
      });
    },
    toPdf() {
      this.hiprintTemplate.toPdf({}, "打印预览");
    }
  }
};